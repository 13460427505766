import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "519px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "57.668711656441715%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAACVklEQVQoz21SS0+TURC9SfdsumzTP4Dpqqsuu7IsJJEFRogUKTs1PhOJD1ipf8AQNRFqoxWhoFEjEnmoBFChpYU+gD4ALQVK3y2lLfT4zcSvWuIkkzv3zsyZMzNXVI7KODossaJyCLrLKt8Py8Wa+79xx23BQH9EBpWVg3BU9ctJx4EpTy4qKNDv8yIUXKsBlcGKB/sYGbYjGo38H7RGKhBzszPQ6XSYmpyoAsptkmysh2A2dzAoSbl0UGVFEtvdhtF4Ep2dZpSKBYiXthfo6enGejjEzv18tqZmLpuB07GAbCZVZUEqF1yY/wFT2zlcunhBwghCWK0W3Ll9C/39TzH99Qt83mVO9nk9SCXjKEpVx8ZGMTH+iZmt+H2I7+3+bV+SrchPbEe32BYGg4EdBOJZdkuOCDLpJLyeJexsb7HtcMyjocHIvs2NMJbcLk6mFikv8msTAwM2aWzjEBqNBu3tJrS0nGWmH0c/IBhY5fPG9WtobW3Bifp6mExtUvtpvH/3FhZLn9RRHx7cvwfrMwsWnQtoajqNrq6bED3dd6FQKKBSqSCEgM32nLebiMfQcb4ddXV10Gq1+Dw1yazCoQAOCnlm19h4CkqlEmq1Gmeam5FI7EGkUwnMzkxjdcUPvV4Pu32QE9OpJH8Z2vCb1yPVDcvz80qzvnrlMp48foTe3odMgGdI23W7nBxIG//+bY5bCwbWJMAC+4btQ/wWi+0ww3wug6HBV3AtOiQiPn6jWZIyQ9omBdFXoCXQ18lLSiDUHmlWsumdlBIL+zl+p3jCoFjq6DcbQ0CPhtv2zgAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Notioni",
        "title": "Notioni",
        "src": "/static/61f48f960df764fe7835d47ecd3305f7/b23ad/Notioni.png",
        "srcSet": ["/static/61f48f960df764fe7835d47ecd3305f7/222b7/Notioni.png 163w", "/static/61f48f960df764fe7835d47ecd3305f7/ff46a/Notioni.png 325w", "/static/61f48f960df764fe7835d47ecd3305f7/b23ad/Notioni.png 519w"],
        "sizes": "(max-width: 519px) 100vw, 519px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`Notion est un outil très intéressant que j'utilise pour moi et pour le suivi des projets clients depuis plusieurs années.  `}</p>
    <h2>{`Notion en quelques mots`}</h2>
    <blockquote>
      <p parentName="blockquote">{`C’est un mélange d’hypertexte, de markdown et de tables / relations sur un matelas de mécanismes de collaboration avec commentaires, historique, et enregistrement /restauration de versions. Il n’y a pas vraiment de limite. Les concepts élémentaires offerts par Notion permettent de construire virtuellement n’importe quel système d’organisation, collaboration et partage de l’information pour une petite ou moyenne équipe. `}</p>
    </blockquote>
    <br />
    <p>{`Depuis le début, Notion est capable d’intégrer des liens de pages web et de les présenter dans un cadre de dimension ajustable.
Une page web peut être virtuellement n’importe quoi y compris des visualisations d’indicateurs (KPI) sur l’état d’un business.
2 sources possible pour les données, potentiellement combinables :`}</p>
    <ul>
      <li parentName="ul">{`une base externe, un fichier csv, json, un classeur Excel ou Google sheet, ..`}</li>
      <li parentName="ul">{`une base de données Notion`}</li>
    </ul>
    <br />
    <h2>{`API officielle ou API non officielle`}</h2>
    <p>{`La prochaine publication de l’API devrait permettre une utilisation souple et simple tout en préservant la confidentialité et la sécurité des données précieuses confiées à Notion. Pour le moment il faut soit rendre publique une page Notion pour y accéder ou bien confier le Token Notion qui donne accès à toutes les données à une application tierce : pas très rassurant.
Il existe déjà plusieurs services qui utilisent (ou pas) l’API non officielle pour proposer des choses plus ou moins utiles. Quelques exemples existants sur le web à la date de publication de ce billet, sans garantie d’intérêt ou de bon fonctionnement :`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://notionmetrics.com/"
        }}>{`Notionmetrics`}</a>{` : widgets de statistiques (Google analytics, mailchimp, stripe, …)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vizydrop.com/notion"
        }}>{`Vizydrop`}</a>{` : graphes et tables à partir de données csv, json, google sheets, …`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chart-nerd.now.sh/"
        }}>{`Chart Nerd`}</a>{` : graphes et tables à partir d’une base de données Notion`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://indify.co/"
        }}>{`Indify`}</a>{` : des widgets généraux (météo, horloge, calendrier, compte à rebours, …)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://perceptible.fr/blog/googleforms/"
        }}>{`perceptible`}</a>{` : synthèse de table Notion sous forme de diagrammes à la Google Forms. `}<strong parentName="li">{`Ma contribution`}</strong>{` aux tableaux de bord Notion. Utile à plusieurs clients encore beta test pour le moment. Feed back apprécié.`}</li>
    </ul>
    <br />
    <p>{`La sortie officielle de l’API va permettre de passer à la vitesse supérieure pour constituer et exploiter des tableaux de bord dans Notion. Un prochain billet y sera entièrement consacré. J’y travaille déjà.`}</p>
    <br />
    <p>{`J'ai écrit deux billets medium sur Notion :`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alain-roan.medium.com/notion-so-un-cerveau-num%C3%A9rique-%C3%A0-plusieurs-87a09a7e6aa5"
        }}>{`Notion.so : Un cerveau numérique à plusieurs ?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alain-roan.medium.com/notion-so-mon-coup-de-foudre-un-an-apr%C3%A8s-707da6ec088c"
        }}>{`Notion.so : Un coup de foudre un an après`}</a></li>
    </ul>
    <br />
    <p>{`Qui résument mon enthousiasme et ma détermination à promouvoir l'outil à tous les interlocuteurs que je rencontre.`}</p>
    <br />
    <p>{`L'outil est absolument génial.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      